import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide10/image/imgPortadaGuia10.png"
import img1 from "@components/pageGuide/guides/guide10/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide10/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide10/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide10/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    Guía para implementar WhatsApp en tu atención al cliente
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Con más de 200 millones de usuarios activos, WhatsApp se ha convertido en
      una herramienta imprescindible para todos los negocios. Sobre todo, para
      mejorar las interacciones entre usuario y empresa. Todo eso gracias a que
      tiene un alcance inmediato, personal y global.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Existen muchas formas y estrategias de implementar WhatsApp dentro de tu
    atención al cliente. La clave está en ofrecer un autoservicio, un
    acompañamiento de postventa y que tu canal pueda escalar a otro sin
    perjudicar la continuidad del contacto.
    <br /> <br />
    Con WhatsApp, las empresas han encontrado un canal muy potente para
    desplegar sus estrategias de atención al cliente. Además, que cuenta con una
    tasa de apertura del 98%. Por eso, en esta guía aprenderás a utilizar a
    WhatsApp en todos los aspectos que abarca el servicio al cliente.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Envío de respuestas automáticas por WhatsApp",
      },
      {
        key: 2,
        text: "Flujo de comunicación luego de una compra",
      },
      {
        key: 3,
        text: "Envío de promociones personalizadas",
      },
      {
        key: 4,
        text: "Ofrecer un autoservicio por WhatsApp",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
